// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdfViewerV2Button .ant-modal-content {
  padding: 0 !important;
}

.pdfViewerV2Button .ant-modal-close {
  top: 0;
  right: -35px;
  border-radius: 50%;
  color: white;
  background-color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/pdf-viewer-v2/PdfViewerV2.styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,MAAM;EACN,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".pdfViewerV2Button .ant-modal-content {\n  padding: 0 !important;\n}\n\n.pdfViewerV2Button .ant-modal-close {\n  top: 0;\n  right: -35px;\n  border-radius: 50%;\n  color: white;\n  background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
