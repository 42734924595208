import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";

// store
import { store } from "@/redux";
// config
// components
import App from "./App";
// styles
import "antd/dist/reset.css";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
);
