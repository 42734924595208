import { createAsyncThunk } from "@reduxjs/toolkit";

// services
import { publicService } from "@/services";

// helpers
import { getUserRole } from "@/helpers";

// redux
import { GET_USER_INFO } from "./auth.actions";

export const getUserInfo = createAsyncThunk(
  GET_USER_INFO,
  async (user: any) => {
    try {
      const res = await publicService.getSecrets("AWS_COGNITO_ORG_ID");
      const userOrgId = user?.profile?.["cognito:groups"][0];
      return {
        ...user,
        role: getUserRole(userOrgId, res.AWS_COGNITO_ORG_ID),
      };
    } catch (err: any) {
      throw new Error(err.message || "Failed to get user info");
    }
  }
);
