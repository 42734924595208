const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || "dev";

const getConfig = () => {
  switch (ENV) {
    case "prod":
      return {
        api: {
          baseUrl: process.env.REACT_APP_API_BASE_URL || "",
          mode: process.env.REACT_APP_API_MODE || "",
          timeout: Number(process.env.REACT_APP_API_TIMEOUT) || 0,
        },
        auth0: {
          baseUrl: "",
          audienceAuth0: "",
          clientSecretForApi: "",
          grantType: process.env.REACT_APP_AUTH0_GRANT_TYPE || "",
          clientIdForApi: "",
          domain: "",
          audience: "",
          clientId: "",
          redirectUri: `${window.location.origin}/${process.env.REACT_APP_AUTH0_REDIRECT_URI}`,
          returnTo: window.location.origin || "",
          adminOrgId: "",
        },
        azure: {
          storageAccountName:
            process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME || "",
        },
        aws: {
          bucketName: process.env.REACT_APP_AWS_BUCKET_NAME || "",
          bucketUrl: process.env.REACT_APP_AWS_BUCKET_URL || "",
        },
        reCaptcha: {
          siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || "",
        },
      };
    case "dev":
      return {
        api: {
          baseUrl: process.env.REACT_APP_API_BASE_URL || "",
          mode: process.env.REACT_APP_API_MODE || "",
          timeout: Number(process.env.REACT_APP_API_TIMEOUT) || 0,
        },
        auth0: {
          baseUrl: "",
          audienceAuth0: "",
          clientSecretForApi: "",
          grantType: process.env.REACT_APP_AUTH0_GRANT_TYPE || "",
          clientIdForApi: "",
          domain: "",
          audience: "",
          clientId: "",
          redirectUri: `${window.location.origin}/${process.env.REACT_APP_AUTH0_REDIRECT_URI}`,
          returnTo: window.location.origin || "",
          adminOrgId: "",
        },
        azure: {
          storageAccountName:
            process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME || "",
        },
        aws: {
          bucketName: process.env.REACT_APP_AWS_BUCKET_NAME || "",
          bucketUrl: process.env.REACT_APP_AWS_BUCKET_URL || "",
        },
        reCaptcha: {
          siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || "",
        },
      };
    case "local":
    default:
      return {
        api: {
          baseUrl: process.env.REACT_APP_API_BASE_URL || "",
          mode: process.env.REACT_APP_API_MODE || "",
          timeout: Number(process.env.REACT_APP_API_TIMEOUT) || 0,
        },
        auth0: {
          baseUrl: "",
          audienceAuth0: "",
          clientSecretForApi: "",
          grantType: process.env.REACT_APP_AUTH0_GRANT_TYPE || "",
          clientIdForApi: "",
          domain: "",
          audience: "",
          clientId: "",
          redirectUri: `${window.location.origin}/${process.env.REACT_APP_AUTH0_REDIRECT_URI}`,
          returnTo: window.location.origin || "",
          adminOrgId: "",
        },
        azure: {
          storageAccountName:
            process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME || "",
        },
        aws: {
          bucketName: process.env.REACT_APP_AWS_BUCKET_NAME || "",
          bucketUrl: process.env.REACT_APP_AWS_BUCKET_URL || "",
        },
        reCaptcha: {
          siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || "",
        },
      };
  }
};

export default getConfig();
